import CableReady from "cable_ready";
import consumer from "./consumer"

consumer.subscriptions.create("GameSpreadsheetChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        if (data.cableReady) {
            CableReady.perform(data.operations)
            app_game_sheet.forceRerender();
            app_game_sheet.$children[0].load_played();
        }
    }
});
